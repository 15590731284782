import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Headers, Response } from '@angular/http';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiResponse } from '../../interfaces/api-response.interface';
import { StateInterface, Account, Saml } from '../../store/state.model';
import { AuthenticationService, SamlService, FeedbackService, LayoutService, LocationsService, CustomNotificationsService, ApplicationSettingsService, CategoriesService, RecipesService, CabinetsService, CabinetPositionsService, RegistrationService, LocaleService, UsersService } from '../../services';
import { BaseComponent } from '../base/base.component';
import { _ } from '../../tools';

interface QueryParams {
	token: string;
	cardCode: string;
	cabinetID: number;
}

interface ApiValidateResponse {
	authorization?: string;
	success: boolean;
	user: {
		id: number;
		email: string;
		firstname: string;
		lastname: string;
		usergroups?: string[];
		conditonsAccepted?: boolean; // @NOTE Typo from API
		isBlacklisted?: boolean;
	};
}

@Component({
	template: require('./login-view.component.html')
})

/**
 * Class representing the LoginViewComponent component.
 */
export class LoginViewComponent extends BaseComponent {

	/**
	 * @type {Account} - The account state.
	 */
	account: Account;

	/**
	 * @type {Saml} - The saml state.
	 */
	saml: Saml;

	/**
	 * @type {string} - The token, provided by SAML (if applicable)
	 */
	SAMLToken: string;

    /**
     * @type {boolean} - Is it the SAML login flow or not?
     */
	isSAMLLogin: boolean = false;

	/**
	 * @type {string} - The card code, provided by the ad-hoc client (if applicable)
	 */
	cardCode: string;

	/**
	 * @type {number} - The cabinet id, provided by the ad-hoc client (if applicable)
	 */
	cabinetID: number;

	/**
	 * @type {FormGroup} - The login form.
	 */
	form: FormGroup;

	/**
	 * @type {boolean} - Is the password visible or not?
	 */
	isPasswordVisible: boolean = false;

	/**
	 * @type {boolean} - Form submit status.
	 */
	isSubmitted: boolean = false;

	/**
	 * @type {boolean} - Is validation in order or not?
	 */
	isValidated: boolean = false;

	/**
	 * Constructor.
	 * @param {AuthenticationService} authenticationService
	 * @param {SamlService} samlService
	 * @param {LayoutService} layoutService
	 * @param {FeedbackService} feedbackService
	 * @param {LocationsService} LocationsService
	 * @param {CustomNotificationsService} CustomNotificationsService
	 * @param {ApplicationSettingsSerive} ApplicationSettingsService
	 * @param {CategoriesService} categoriesService
	 * @param {RecipesService} recipesService
	 * @param {CabinetsService} cabinetsService
	 * @param {CabinetPositionsService} cabinetPositionsService
	 * @param {RegistrationService} registrationService
	 * @param {LocaleService} localeService
	 * @param {UsersService} usersService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private authenticationService: AuthenticationService,
		private samlService: SamlService,
		private layoutService: LayoutService,
		private feedbackService: FeedbackService,
		private locationsService: LocationsService,
		private customNotificationsService: CustomNotificationsService,
		private applicationSettingsService: ApplicationSettingsService,
		private categoriesService: CategoriesService,
		private recipesService: RecipesService,
		private cabinetsService: CabinetsService,
		private cabinetPositionsService: CabinetPositionsService,
		private registrationService: RegistrationService,
		private localeService: LocaleService,
		private usersService: UsersService,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>) {
		super();

		// Subscribes to router events
		this.addSubscription(this.router.events.pipe(
			filter(routerEvent => routerEvent instanceof NavigationEnd))
			.subscribe(routerEvent => {
				const url = router.url.toString();
				// If it doesn't work take a look at the comment below
				// const { url } = routerEvent;

				// Immediately redirects to portal
				console.log(url);
				if (url === '/saml') {
					this.onSecondaryActionClick(null, null);
				}
			})
		);

		// Subscribes to route param(s) (if applicable)
		this.addSubscription(this.route.queryParams
			.subscribe((queryParams: QueryParams) => {
				if (queryParams) {
					const { token, cardCode, cabinetID } = queryParams;
					this.SAMLToken = token || undefined;
					this.cardCode = cardCode || undefined;
					this.cabinetID = cabinetID || undefined;

					if (this.cabinetID) {
						sessionStorage.setItem('cabinetID', JSON.stringify(this.cabinetID));
					}
					this.isSAMLLogin = !!this.SAMLToken;
				}
			})
		);

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);

				if (this.account) {
					const { bearer, isLoggedIn } = this.account;

					// Posts check token call, if applicable
					if (bearer && bearer === this.SAMLToken) {
						this.SAMLToken = undefined;
						this.handleValidationResponse(
							this.authenticationService.doPostCheckToken()
						);
					}

					// @TODO add check for role and redirect appropriately below:

					// Redirects to /overview if logged in
					if (bearer && isLoggedIn) {
						this.router.navigate(this.account.conditionsAccepted
							? ['/reservation/overview']
							: ['/onboarding']
						);
					}
				}
			})
		);

		// Subscribes to the saml state
		this.addSubscription(store.pipe(select('saml'))
			.subscribe((saml: Saml) => {
				this.saml = _.cloneDeep(saml);

				if (this.saml) {
					const { loginUrl, isLoading } = this.saml;

					// Resets saml state and redirects to portal login page when a url is available
					if (loginUrl && !isLoading) {
						store.dispatch(this.samlService.resetState());
						window.location.href = loginUrl;
					}
				}
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		const { username, password } = this.account.formData;

		// Sets form (fields)
		this.form = new FormGroup({
			username: new FormControl(username, Validators.required),
			password: new FormControl(password, Validators.required)
		});

		// Loads bearer from SAML token, if applicable
		if (this.SAMLToken) {
			this.store.dispatch(
				this.authenticationService.storeBearer(this.SAMLToken)
			);
		}

		// Validates card code, if applicable
		if (this.cardCode) {
			this.handleValidationResponse(
				this.authenticationService.doPostValidateCard(this.cardCode)
			);
		}
	}

	/**
	 * Return wether or not should focus on a specific field.
	 * @param {string} field
	 * @return {boolean}
	 */
	getShouldFocus(field: string): boolean {
		const { isValidated, form } = this;
		const fields = Object.keys(form.controls);
		let isOtherFieldsValid = true;

		// Checks if there are any invalid fields before this one
		fields.slice(0, fields.findIndex(control => control === field))
			.map(control => {
				if (!form.get(control).valid) {
					isOtherFieldsValid = false;
				}
			});
		return (isValidated && isOtherFieldsValid && !form.get(field).valid);
	}

	/**
	 * Upon user changing form input data.
	 * @param {string} field - The form field
	 * @param {any} value - The value
	 * @return {void}
	 */
	onInputChange(field: string, value: any): void {
		const { formData } = this.account;
		let { isValidated, form } = this;

		setTimeout(() => {
			isValidated = false;

			this.store.dispatch(
				this.authenticationService.editFormData({
					...formData,
					[field]: value
				})
			);
		});
	}

	/**
	 * Submits the form if the user hits the Enter key.
	 * @param {object} event - The event
	 * @return {void}
	 */
	onKeypress(event: any): void {
		const { isLoading } = this.account;

		if (event.keyCode === 13 && !isLoading) {
			this.onSubmit();
		}
	}

	/**
	 * Perform and handle a get saml login call.
	 * @param {object} $event
	 */
	onSecondaryActionClick($event: any, idp: string): void {
		console.log(idp);
		$event ? $event.preventDefault() : null;
		this.samlService.doGetSamlLogin(idp)
			.pipe(map((res: Response) => res.json()))
			.subscribe((data: { loginURL: string }) => {
				const { loginURL } = data;

				// Stores SAML login url
				if (loginURL) {
					this.store.dispatch(
						this.samlService.setLoginUrl(loginURL)
					);
				}

				this.store.dispatch(
					this.samlService.setIsLoading(false)
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.samlService.setIsLoading(false))
				)
			));
	}

	/**
	 * Post login credentials if form is valid and submitted;
	 * @return {void}
	 */
	onSubmit(): void {
		const { isLoading } = this.account;
		const { form } = this;

		this.isValidated = true;
		this.isSubmitted = true;

		if (form.valid && !isLoading) {
			this.handleValidationResponse(
				this.authenticationService.doPostValidate({
					username: form.get('username').value,
					password: form.get('password').value
				})
			);
		}
	}

	/**
	 * Handle a validation call (either login or check token) and hydrate the store accordingly.
	 * @private
	 * @param {Observable} response
	 * @return {void}
	 */
	private handleValidationResponse(response: Observable<Response>): void {
		response.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiValidateResponse) => {
				const { success } = data;

				if (success) {
					const { id, email, firstname, lastname, usergroups, conditonsAccepted, isBlacklisted } = data.user;

					// Loads the account into the store
					this.store.dispatch(
						this.authenticationService.loadAccount({
							...this.account,
							userGroups: usergroups,
							isNew: sessionStorage.getItem('isNotNew') ? false : true,
							isLoggedIn: true,
							id,
							profile: {
								firstName: firstname,
								lastName: lastname,
								email: email
							},
							conditionsAccepted: conditonsAccepted,
							isBlacklisted
						})
					);

					// Hydrates the store with other data
					// @TODO check user role and rights
					this.doHydrateCategories();
					this.doHydrateLocations();
					this.doHydrateCustomNotifications();
					this.doHydrateApplicationSettings();
					this.doHydrateRecipes();
					this.doHydrateCabinets();
					this.doHydrateCabinetPositions();
					this.doHydrateUserMetaData();
					this.doHydrateRegistrations();

					// Deactivates bottom bar items based on user role/permission
					this.store.dispatch(
						this.layoutService.deactivateBottomBarItems(usergroups, !!this.cardCode)
					);

					if (this.cardCode) { // @NOTE perhaps find a better way to 'verify' this is an ad hoc session?
						this.store.dispatch(
							this.registrationService.setIsAdHoc(true)
						);
					}
				}
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.authenticationService.setIsLoading(false))
				)
			));
	}

	/**
	 * Perform a call to hydrate categories.
	 * @private
	 * @return {void}
	 */
	private doHydrateCategories() {
		this.categoriesService.hydrateCategories().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.store.dispatch(
						this.categoriesService.loadCategories(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.categoriesService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.categoriesService.setIsLoading(false)
				))
			));
	}

	/**
	 * Perform a call to hydrate locations.
	 * @private
	 * @return {void}
	 */
	private doHydrateLocations() {
		this.locationsService.hydrateLocations().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.store.dispatch(
						this.locationsService.loadLocations(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.locationsService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.locationsService.setIsLoading(false)
				))
			));
	}

	/**
	 * Perform a call to hydrate locations.
	 * @private
	 * @return {void}
	 */
	private doHydrateCustomNotifications() {
		this.customNotificationsService.hydrateCustomNotifications().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.store.dispatch(
						this.customNotificationsService.loadCustomNotifications(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.customNotificationsService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.customNotificationsService.setIsLoading(false)
				))
			));
	}

	/**
	 * Perform a call to hydrate locations.
	 * @private
	 * @return {void}
	 */
	private doHydrateApplicationSettings() {
		this.applicationSettingsService.hydrateApplicationSettings().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.store.dispatch(
						this.applicationSettingsService.loadApplicationSettings(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.applicationSettingsService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.applicationSettingsService.setIsLoading(false)
				))
			));
	}

	/**
	 * Perform a call to hydrate recipes.
	 * @private
	 * @return {void}
	 */
	private doHydrateRecipes() {
		this.recipesService.hydrateRecipes(this.cabinetID).pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.store.dispatch(
						this.recipesService.loadRecipes(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.recipesService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.recipesService.setIsLoading(false)
				))
			));
	}

	/**
	 * Perform a call to hydrate cabinets.
	 * @private
	 * @return {void}
	 */
	private doHydrateCabinets() {
		this.cabinetsService.hydrateCabinets().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.store.dispatch(
						this.cabinetsService.loadCabinets(result)
					);
				}

			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.cabinetsService.setIsLoading(false)
				))
			));
	}

	/**
	 * Perform a call to hydrate cabinet positions.
	 * @private
	 * @return {void}
	 */
	private doHydrateCabinetPositions() {
		this.cabinetPositionsService.hydrateCabinetPositions().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.store.dispatch(
						this.cabinetPositionsService.loadCabinetPositions(result)
					);
				}

			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.cabinetPositionsService.setIsLoading(false)
				))
			));
	}
	/**
	 * Perform a call to hydrate the user meta data.
	 * @private
	 * @return {void}
	 */
	private doHydrateUserMetaData() {
		this.usersService.getUserMetaData().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data) => {
				this.store.dispatch(
					this.usersService.setUserMeta(data)
				);

				if (typeof data.preferedLanguage === 'string') {
					// Returns nl-NL, store expects a 2 char language.
					this.store.dispatch(
						this.localeService.setCurrent(data.preferedLanguage.substring(0, 2)),
					);
				}
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error)
			));
	}

	/**
	 * Perform a call to hydrate the users reservations / registrations.
	 * @private
	 * @return {void}
	 */
	private doHydrateRegistrations() {
		this.registrationService.getActiveRegistrations().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data) => {
				this.store.dispatch(
					this.registrationService.setActiveReservations(data.result),
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error)
			));
	}
}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseComponent } from '../../base/base.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'search-block',
	template: require('./search-block.component.html')
})

/**
 * Class representing the SearchBlock component.
 */
export class SearchBlockComponent extends BaseComponent implements OnInit {

	/**
	 * The label
	 * @type {string}
	 */
	@Input() label: string;

	/**
	 * Should we render a filter form or not?
	 * @type {boolean}
	 */
	@Input() hasForm: boolean = true;

	/**
	 * The value
	 * @type {string}
	 */
	 @Input() value: string;

	/**
	 * The onApply event emitter.
	 * @type {EventEmitter}
	 */
	@Output() onApply: EventEmitter<string> = new EventEmitter();

	/**
	 * The search query field.
	 * @type {FormControl}
	 */
	query: FormControl = new FormControl();

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		if (this.hasForm) {

			// Adds debouncer to the query input field
			this.addSubscription(this.query.valueChanges.pipe(
				debounceTime(400),
				distinctUntilChanged())
				.subscribe(value => this.onApply.emit(value))
			);
		}
	}

	onSubmit(event: any) {
		event.preventDefault();
	}
}

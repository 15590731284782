import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Headers, Response } from '@angular/http';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface, Account, Locations, Cabinets, Categories, ManagementRecipes } from '../../../store/state.model';
import { ManagementRecipeEnriched } from '../../../store/management-recipes/management-recipes.models';
import { LayoutService, AuthenticationService, ManagementRecipesService } from '../../../services';
import { UserMeta } from '../../../store/auth/auth.models';
import { RecipesHelper } from '../../../helpers/recipes/recipes.helper';
import { BaseComponent } from '../../base/base.component';
import { _, getState, tassign } from '../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./recipes-view.component.html')
})

/**
 * Class representing the RecipesViewcomponent component.
 */
export class RecipesViewComponent extends BaseComponent implements OnInit {

	unassigned: boolean = false;
	didLoadRecipes: boolean = false;

	/**
	 * The locations state.
	 * @type {Locations}
	 */
	locations: Locations;

	/**
	 * The categories state.
	 * @type {Categories}
	 */
	categories: Categories;

	/**
	 * The cabinets state.
	 * @type {Cabinets}
	 */
	cabinets: Cabinets;

	/**
	 * The recipes state.
	 * @type {Recipes}
	 */
	recipes: ManagementRecipes;

	/**
	 * The recipes state.
	 * @type {Recipes}
	 */
	filteredRecipes: Array<ManagementRecipeEnriched>;

	availableLabels: string[] = ['unavailable', 'overdue', 'defect', 'lent'];
	selectedLabel: number = -1;
	selectedLocation: number = -1;
	selectedCategory: number = -1;
	currentFilterQuery: string;

	/**
	 * @property {Account} account - The account state.
	 */
	account: Account;

	/**
	 * @property {UserMeta} userMeta - The user meta data
	 */
	userMeta: UserMeta = {
		preferedLocationId: 0,
		preferedLanguage: 'nl',
	};

	/**
	   * Constructor.
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	   * @param {Store} store
	   * @return {void}
	   */
	constructor(
		private layoutService: LayoutService,
		private router: Router,
		private authenticationService: AuthenticationService,
		private managementRecipesService: ManagementRecipesService,
		private store: Store<StateInterface>) {
		super();

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets'))
			.subscribe((cabinets: Cabinets) => {
				this.cabinets = _.cloneDeep(cabinets);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);
			})
		);

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
				this.userMeta = this.account.meta;
				this.selectedLocation = this.selectedLocation === -1 ? this.userMeta.preferedLocationId : this.selectedLocation;
			})
		);

		// Fetch new list of recipes
		this.managementRecipesService.hydrateManagementRecipesByLocationID(this.selectedLocation)
			.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;
				if (success) {
					this.didLoadRecipes = true;
					this.store.dispatch(
						this.managementRecipesService.loadRecipes(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.managementRecipesService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.managementRecipesService.setIsLoading(false)
				))
			));
		this.unassigned = false;

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('managementRecipes'))
			.subscribe((recipes: ManagementRecipes) => {
				if (this.didLoadRecipes) {
					this.recipes = _.cloneDeep(recipes);

					const { items } = getState(this.store).managementRecipes;
					let recipes_available: any = items;

					if (this.recipes) {
						// Adds metadata
						this.recipes.items = this.recipes.items.map(recipe => ({
							...RecipesHelper.getEnrichedManagementRecipe(
								recipe,
								recipe.isActive ? recipe : null,
								getState(store)
							)
						}));
						// Sets filtered recipes
						this.filteredRecipes = [...this.recipes.items];
					}
					this.applyFilter(this.currentFilterQuery);
				}
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				leftNav: { label: 'overview-text', handler: () => this.router.navigate(['/reservation/overview']) },
				rightNav: null
			})
		);
		if (sessionStorage.getItem('managementQuery')) {
			this.currentFilterQuery = sessionStorage.getItem('managementQuery');
		}
		if (sessionStorage.getItem('managementSelectedLabel')) {
			this.selectedLabel = Number(sessionStorage.getItem('managementSelectedLabel'));
		}
		if (sessionStorage.getItem('managementSelectedCategory')) {
			this.selectedCategory = Number(sessionStorage.getItem('managementSelectedCategory'));
		}
	}

	applyLabelFilter(index: number): void {
		this.selectedLabel = index;
		this.applyFilter(this.currentFilterQuery);
	}

	applyCategoryFilter(index: number): void {
		this.selectedCategory = Number(index);
		this.applyFilter(this.currentFilterQuery);
	}

	applyLocationFilter(index: number): void {
		this.selectedLocation = +index;
		// Fetch new list of recipes
		this.managementRecipesService.hydrateManagementRecipesByLocationID(this.selectedLocation)
			.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.didLoadRecipes = true;
					this.store.dispatch(
						this.managementRecipesService.loadRecipes(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.managementRecipesService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.managementRecipesService.setIsLoading(false)
				))
			));
		this.unassigned = false;
		this.applyFilter(this.currentFilterQuery);
	}

	/**
	 * Apply a search query filter.
	 * @param {string} query
	 */
	applyFilter(query: string): void {
		const { items } = this.recipes;
		const { current } = getState(this.store).locale;

		this.currentFilterQuery = query;

		this.filteredRecipes = items.filter(recipe => {
			const { name, category, location } = recipe;
			const hasQuery = (haystack, needle) => haystack ? haystack.search(new RegExp(needle, 'i')) !== -1 : false;

			if (query) {
				sessionStorage.setItem('managementQuery', query);
				// Searches recipe metadata
				let inName = hasQuery(name[current], query);
				let inCategory = category ? hasQuery(category.name[current], query) : false;
				let inLocation = location ? hasQuery(location.name[current], query) : false;

				return (inName || inCategory || inLocation) || false;
			}
			sessionStorage.removeItem('managementQuery');
			return true;
		})
			.filter((recipe: any) => {
				const { labels } = recipe;
				if (this.selectedLabel === -1) {
					return true;
				}
				return labels.find((label: string) => label === this.availableLabels[this.selectedLabel]);
			})
			.filter((recipe: any) => {
				const { locationId } = recipe;
				if (locationId == null || this.selectedLocation === -1 || this.selectedLocation === 0) {
					return true;
				}
				return +locationId === this.selectedLocation;
			})
			.filter((recipe: any) => {
				if (this.selectedCategory === -1 || this.selectedCategory === 0) {
					return true;
				}
				return recipe.categoryId === this.selectedCategory;
			});
		sessionStorage.setItem('managementSelectedLabel', this.selectedLabel.toString());
		sessionStorage.setItem('managementSelectedCategory', this.selectedCategory.toString());
	}

	applyUnassignedFilter() {
		this.unassigned = true;
		this.managementRecipesService.hydrateUnassignedManagementRecipes()
			.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				if (success) {
					this.didLoadRecipes = true;
					this.store.dispatch(
						this.managementRecipesService.loadRecipes(result)
					);
					return;
				}

				this.store.dispatch(
					this.authenticationService.doHandleError(data, this.store.dispatch(
						this.managementRecipesService.setIsLoading(false)
					))
				);
			}, error => this.store.dispatch(this.authenticationService
				.doHandleError(error, this.store.dispatch(
					this.managementRecipesService.setIsLoading(false)
				))
			));
		this.selectedCategory = -1;
		this.selectedLabel = -1;
		this.selectedLocation = -1;
		this.currentFilterQuery = '';
		this.applyFilter(this.currentFilterQuery);
	}
}

import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { StateInterface, Account, Locations, Categories, Filter, Recipes, Registration, Locale } from '../../../store/state.model';
import { Recipe, RecipeEnriched, RecipesAvailabilityItem } from '../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../interfaces/api-response.interface';
import { AuthenticationService, RegistrationService, RecipesService, FeedbackService, LayoutService } from '../../../services';
import { RecipesHelper, DateTimeHelper } from '../../../helpers';
import { UseTranslationPipe } from '../../../pipes';
import { BaseComponent } from '../../base/base.component';
import { _, tassign, getState, Moment } from '../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./registration-view.component.html')
})

/**
 * Class representing the RegistrationViewComponent component.
 */
export class RegistrationViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * @type {Account} - The account state.
	 */
	account: Account;

	/**
	 * @type {Locations} - The locations state.
	 */
	locations: Locations;

	/**
	 * @type {Categories} - The categories state.
	 */
	categories: Categories;

	/**
	 * @type {Filter} - The recipes state.
	 */
	filter: Filter;

	/**
	 * @type {Recipes} - The recipes state.
	 */
	recipes: Recipes;

	/**
	 * @type {Registration} - The registration state.
	 */
	registration: Registration;

	/**
	 * @type {RecipeEnriched} - The current recipe.
	 */
	recipe: RecipeEnriched;

	/**
	 * @type {number} - The current recipe id.
	 */
	recipeId: number;

	/**
	 * @type {boolean} - The current filter mode
	 */
	isAvailability: boolean = true;

	/**
	 * @type {boolean} - Has the form been submitted or not?
	 */
	isSubmitted: boolean = false;

	/**
	 * @type {boolean}
	 */
	showAvailability: boolean = false;

	/**
	 * @type {boolean} - Has the image src loaded or not?
	 */
	hasImageLoaded: boolean = false;


	/**
	 * Constructor.
	 * @param {string} imagesUrl
	 * @param {AuthenticationService} authenticationService
	 * @param {RegistrationService} registrationService
	 * @param {RecipesService} recipesService
	 * @param {FeedbackService} feedbackService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ImagesUrl') private imagesUrl: string,
		private authenticationService: AuthenticationService,
		private registrationService: RegistrationService,
		private recipesService: RecipesService,
		private feedbackService: FeedbackService,
		private layoutService: LayoutService,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>,
		private translate: TranslateService) {
		super();

		// Subscribes to route params
		this.addSubscription(this.route.params
			.subscribe(params => {
				this.recipeId = +params['id'] || 0;
			})
		);

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
			})
		);

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);
			})
		);

		// Subscribes to the filter state
		this.addSubscription(store.pipe(select('filter'))
			.subscribe((filter: Filter) => {
				this.filter = _.cloneDeep(filter);
			})
		);

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('recipes'))
			.subscribe((recipes: Recipes) => {
				this.recipes = _.cloneDeep(recipes);

				if (this.recipes && this.recipes.items.length && this.locations.items.length && this.categories.items.length) {
					const { items } = this.recipes;
					const { date } = this.filter;
					const filterDate = DateTimeHelper.getDate(date, 'DD-MM-YYYY');

					// Finds current recipe
					const recipe = _.cloneDeep(items
						.find(recipe => recipe.id === this.recipeId));

					this.recipe = {

						// Adds category and location metadata
						...RecipesHelper.getEnrichedRecipe(recipe, {
								categories: this.categories, locations: this.locations
							}),
						// Adds availability metadata
						availability: recipe.availability.map((slot, index) => {
							return tassign(slot, {
								isSlotOpen: filterDate ? DateTimeHelper.getDate(slot.date, 'YYYY-MM-DD') === filterDate : index === 0 || false
							});
						}),
						// Adds the description property
						description: recipe.description
					};
				}
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);

				if (this.registration) {
					const { isSuccessful, isLoading } = this.registration;

					// Submits a post
					if (isLoading && !this.isSubmitted) {
						this.isSubmitted = true;
						this.doSubmitRegistration();
					}

					// Routes to confirmation when registration was processed succesfully
					if (isSuccessful && !isLoading) {
						this.router.navigate(['/reservation/confirmation']);
					}
				}
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Clears registration state
		this.store.dispatch(
			this.registrationService.resetReservation()
		);

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				rightNav: null, leftNav: {
					label: 'overview-text',
					handler: () => this.router.navigate(['/reservation/overview'])
				}
			})
		);
	}

	/**
	 * When the view is rendered.
	 * @return {void}
	 */
	ngAfterViewInit(): void {
		setTimeout(() => {

			// Toggles showing availability
			this.showAvailability = true;

			// Fetch most recent recipes to get up-to-date availability
			this.recipesService.hydrateRecipes(JSON.parse(sessionStorage.getItem('cabinetID') || 'null'))
				.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
				.subscribe((data: ApiResponse) => {
					const { success, result } = data;

					if (success) {
						this.store.dispatch(
							this.recipesService.loadRecipes(result)
						);
						return;
					}

					this.store.dispatch(
						this.authenticationService.doHandleError(data, this.store.dispatch(
							this.recipesService.setIsLoading(false)
						))
					);

				}, error => this.store.dispatch(this.authenticationService
					.doHandleError(error, this.store.dispatch(
						this.recipesService.setIsLoading(false)
					))
				));

			this.registrationService.getActiveRegistrations()
				.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
				.subscribe((data) => {
					this.store.dispatch(
						this.registrationService.setActiveReservations(data.result),
					);
				}, error => this.store.dispatch(this.authenticationService
					.doHandleError(error)
				));
		});
	}

	/**
	 * Toggle isSlotOpen for a specific availability slot.
	 * @param {number} slotIndex - The index of the intended availability slot
	 * @return {void}
	 */
	onToggleSlot(slotIndex: number): void {
		let { isSlotOpen } = this.recipe.availability[slotIndex];
		this.recipe.availability.map(slot => slot.isSlotOpen = false);
		this.recipe.availability[slotIndex].isSlotOpen = !isSlotOpen;
	}

	/**
	 * Toggle isAvialability.
	 * @param {boolean} to
	 * @return {void}
	 */
	toggleIsAvailability(to: boolean): void {
		this.isAvailability = to;
	}

	/**
	 * Check if reservation passes the business rules.
	 * @param {number} startDTinUnix - Start datetime in unix.
	 * @param {number} endDTinUnix - End datetime in unix.
	 * @param {boolean} isAdHoc - Is it an adhoc reservation?
	 * @return {any}
	 */
	reservationPassesBusinessRules(startDTinUnix: number, endDTinUnix: number, isAdHoc: boolean): any {
		const { userGroups } = this.account;
		let passThrough = true;
		let activeRegistrations = 0;
		let activeAdHocRegistrations = 0;
		const startDT = Moment.unix(startDTinUnix);
		const endDT = Moment.unix(endDTinUnix);
		let error = '';

		// Count registrations
		this.registration.activeRegistrations.forEach((registration) => {
			activeRegistrations++;
			if (registration.isAdHoc) {
				activeAdHocRegistrations++;
			}
		});

		if (userGroups.indexOf('superusers') > -1 || userGroups.indexOf('administrators') > -1) {
			// allow all

		} else if (userGroups.indexOf('employees') > -1) {
			// Medewerker mag 3 actieve leenacties hebben (totaal voor alle locaties)
			if (activeRegistrations >= 3 ) {
				error = 'max-amount-of-employee-reservations';
				passThrough = false;
			}

			// Medewerker mag 1 actieve ad-hoc leenactie hebben (totaal voor alle locaties)
			if (isAdHoc && activeRegistrations >= 1) {
				error = 'max-amount-of-employee-adhoc-reservations';
				passThrough = false;
			}

		} else if (userGroups.indexOf('students') > -1) {
			// Student mag 2 actieve leenactie hebben (totaal voor alle locaties). Dus actieve reserveringen die groter of gelijk zijn aan 2
			if (activeRegistrations >= 2 ) {
				error = 'max-amount-of-student-reservations';
				passThrough = false;
			}
		}

		return {
			success: passThrough,
			error: error,
		};
	}

	/**
	 * Update the current registration and trigger submitting (post) a slot.
	 * @param {number} slotIndex - The index of the intended availability slot
	 * @param {object} $event
	 * @return {void}
	 */
	onSubmit(slotIndex: number, $event: any): void {
		const { registration, recipes } = getState(this.store);
		const { id } = this.recipe;

		// Sets corresponding date
		const date = recipes.items.find(recipe => recipe.id === id)
			.availability[slotIndex].date;
		const startDT = DateTimeHelper.getUnixTimeStamp(date, $event.start);
		const endDT = DateTimeHelper.getUnixTimeStamp($event.endDate || date, $event.end);

		const reservationPassesBusinessRules = this.reservationPassesBusinessRules(startDT, endDT, registration.isAdHoc);

		if (reservationPassesBusinessRules.success) {
			// Edits registration (which triggers a submit)
			this.store.dispatch(
				this.registrationService.editRegistration(tassign(registration, {
					recipeId: id,
					slotIndex,
					formData: {
						...registration.formData,
						dateTime: DateTimeHelper.getUnixTimeStamp(date, $event.start),
						endDateTime: DateTimeHelper.getUnixTimeStamp($event.endDate || date, $event.end),
					},
					status: 1, // @TODO will be removed (ask Rick)
					registrationType: 0, // Lending
					isLoading: true,
					isSuccessful: false
				}))
			);

		} else {
			this.translate.get('RESERVATION.ERRORS.' + reservationPassesBusinessRules.error).subscribe((translation: string) => {
				this.store.dispatch(this.feedbackService.addNotification({
					text: translation,
				}));
			});
		}
	}

	/**
	 * Perform a call to submit a registration.
	 * @private
	 * @return {void}
	 */
	private doSubmitRegistration(): void {
		setTimeout(() => {
			this.recipesService.setLastUpdated(null);
			this.registrationService.submitRegistration()
				.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
				.subscribe((data: ApiResponse) => {
					const { success } = data;

					if (success) {
						this.store.dispatch(
							this.registrationService.editRegistration(tassign(this.registration, {
								isLoading: false,
								isSuccessful: true
							}))
						);
						return;
					}

					this.store.dispatch(
						this.authenticationService.doHandleError(data, this.store.dispatch(
							this.registrationService.setIsLoading(false)
						))
					);
				}, error => this.store.dispatch(this.authenticationService
					.doHandleError(error, this.store.dispatch(
						this.registrationService.setIsLoading(false)
					))
				));
		});
	}
}

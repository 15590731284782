import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';

import { StateInterface, Locale, Categories } from '../../../../store/state.model';
import { ReportsService, IUsageReportItem, RegistrationService } from '../../../../services';
import { BaseComponent } from '../../../base/base.component';
import { _, Moment } from '../../../../tools';
import Papa from 'papaparse';

const Flatpickr = require('flatpickr');
const Dutch = require('flatpickr/dist/l10n/nl.js').nl;

@Component({
	template: require('./report-usage-extended-view.component.html')
})

/**
 * Class representing the RecipeViewComponent component.
 */
export class ReportUsageExtendedViewComponent extends BaseComponent implements OnInit {
	public activity: IUsageReportItem[];
	public startDate: string = Moment().startOf('day').format('YYYY-MM-DD');
	public endDate: string = Moment().endOf('day').format('YYYY-MM-DD');
	@ViewChild('dateInputStart', { static: true }) dateInputStart: ElementRef;
	@ViewChild('dateInputEnd', { static: true }) dateInputEnd: ElementRef;
	private datePickerStart: any;
	private datePickerEnd: any;
	private selectedCategoryId: any;
	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * The recipe form.
	 * @type {FormGroup}
	 */
	form: FormGroup;

	/**
	 * @type {Categories} The categories state snapshot.
	 */
	categories: Categories;
	categoryName: any;

	/**
	 * Constructor.
	 * @param {LayoutService} layoutService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private reportsService: ReportsService,
		private registrationService: RegistrationService,
		private store: Store<StateInterface>,
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories')).subscribe((categories: Categories) => {
			this.categories = _.cloneDeep(categories);
		}));

		this.selectedCategoryId = this.categories.items[0].id;
		this.categoryName = this.categories.items.find(x => x.id === this.selectedCategoryId).name[this.locale.current];
		this.getReport();

		const flatPickrConfigStart = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.startDate,
			maxDate: this.endDate,
			onChange: (selectedDates, dateStr, instance) => {
				this.startDate = dateStr;
				this.datePickerEnd.set('minDate', this.startDate);
				this.getReport();
			}
		};
		setTimeout(() => {
			this.datePickerStart = new Flatpickr(
				this.dateInputStart.nativeElement,
				this.locale.current === 'nl'
					? { ...flatPickrConfigStart, locale: Dutch }
					: flatPickrConfigStart
			);
		});

		const flatPickrConfigEnd = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.startDate,
			minDate: this.startDate,
			onChange: (selectedDates, dateStr, instance) => {
				this.endDate = dateStr;
				this.datePickerStart.set('maxDate', this.endDate);
				this.getReport();
			}
		};
		setTimeout(() => {
			this.datePickerEnd = new Flatpickr(
				this.dateInputEnd.nativeElement,
				this.locale.current === 'nl'
					? { ...flatPickrConfigEnd, locale: Dutch }
					: flatPickrConfigEnd
			);
		});
	}

	private getReport(): void {
		this.activity = undefined;
		this.reportsService.getExtendedUsageReport(Moment(this.startDate).startOf('day').unix(), Moment(this.endDate).endOf('day').unix(), this.selectedCategoryId).subscribe((data: any) => {
			if (data && data._body) {
				let response: any = JSON.parse(data._body);
				if (response.success) {
					this.activity = response.result || [];
					this.categoryName = this.categories.items.find(x => x.id === this.selectedCategoryId).name[this.locale.current];
				}
			}
		});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);
	}

	onApply(selectedCategoryId: any): void {
		this.selectedCategoryId = Number(selectedCategoryId);
		this.getReport();
	}

	public downloadCSV(): void {
		this.reportsService.downloadExtendedReport(Moment(this.startDate).startOf('day').unix(), Moment(this.endDate).endOf('day').unix(), this.selectedCategoryId).subscribe((data: any) => {
			const csvRaw = data._body;

			let csvData = Papa.parse(csvRaw);
			csvData.data[0].unshift('category');
			csvData.data[1].unshift(this.categories.items[this.selectedCategoryId].name[this.locale.current]);

			const csv = Papa.unparse(csvData);
			this.createCSVFile(csv);
		});
	}

	private createCSVFile(csv: any): void {
		if (window.navigator.msSaveOrOpenBlob) {
			let blob = new Blob([decodeURIComponent(encodeURI(csv))], {
				type: 'text/csv;charset=utf-8;'
			});
			navigator.msSaveBlob(blob, Date.now() + '.csv');
		} else {
			let a = document.createElement('a');
			a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
			a.target = '_blank';
			a.download = Date.now() + '.csv';
			document.body.appendChild(a);
			a.click();
		}
	}
}

import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { map } from 'rxjs/operators';

import { StateInterface, Locale, Cabinets, Categories } from '../../../../store/state.model';
import { ReportsService, IUsageReportItem, RegistrationService, AuthenticationService, UsersService, User, CabinetsService, CategoriesService } from '../../../../services';
import { RecipesHelper } from '../../../../helpers/recipes/recipes.helper';
import { BaseComponent } from '../../../base/base.component';
import { _, getState, Moment } from '../../../../tools';
import { Cabinet } from 'src/app/store/cabinets/cabinets.models';
import { Category } from 'src/app/store/categories/categories.models';

const Flatpickr = require('flatpickr');
const Dutch = require('flatpickr/dist/l10n/nl.js').nl;

@Component({
	template: require('./report-usage-view.component.html')
})

/**
 * Class representing the RecipeViewComponent component.
 */
export class ReportUsageViewComponent extends BaseComponent implements OnInit {
	public activity: IUsageReportItem[];
	public filteredActivity: IUsageReportItem[];
	/**
	 * @type {EventEmitter} - The component apply event emitter.
	 */
	@Output() apply: EventEmitter<any> = new EventEmitter();

	/**
	 * The users
	 * @type {User[]}
	 */
	users: User[];

	/**
	 * The cabinets
	 * @type {Cabinets}
	 */
	cabinets: Cabinets;

	/**
	 * The categories
	 * @type {Categories}
	 */
	categories: Categories;

	/**
	 * The filtered users
	 * @type {User[]}
	 */
	filteredUsers: User[];
	selectedUser: number;
	filteredCabinets: Cabinet[];
	selectedCabinet: number;
	filteredCategories: Category[];
	selectedCategory: number;
	public statuses: any = [
		{
			id: 0
		},
		{
			id: 1
		},
		{
			id: 2
		},
		{
			id: 3
		},
		{
			id: 4
		},
		{
			id: 5
		},
		{
			id: 6
		},
		{
			id: 7
		}
	];
	selectedStatus: number;
	selectedOption: any;
	public lateOptions: any = [
		{
			descr: 'Telaat',
			id: 0
		},
		{
			descr: 'Optijd',
			id: 1
		}
	];

	public startDate: string = Moment().startOf('day').format('YYYY-MM-DD');
	public endDate: string = Moment().endOf('day').format('YYYY-MM-DD');
	@ViewChild('dateInputStart', { static: true }) dateInputStart: ElementRef;
	@ViewChild('dateInputEnd', { static: true }) dateInputEnd: ElementRef;
	private datePickerStart: any;
	private datePickerEnd: any;
	private locale: Locale;

	/**
	 * Constructor.
	 * @param {LayoutService} layoutService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private reportsService: ReportsService,
		private registrationService: RegistrationService,
		private authenticationService: AuthenticationService,
		private usersService: UsersService,
		private store: Store<StateInterface>,
	) {
		super();

		this.usersService.getUsers().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;
				this.users = _.cloneDeep(result);
				this.filteredUsers = this.users.sort((a, b) => a.FirstName < b.FirstName ? -1 : 1);
			}, (error: any) => {
				console.log('error', error);
			});

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets'))
			.subscribe((cabinets: Cabinets) => {
				this.cabinets = _.cloneDeep(cabinets);
				this.filteredCabinets = [...this.cabinets.items];
			})
		);

		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);
				this.filteredCategories = [...this.categories.items];
		}));
		this.getReport();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		const flatPickrConfigStart = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.startDate,
			maxDate: this.endDate,
			onChange: (selectedDates, dateStr, instance) => {
				this.startDate = dateStr;
				this.datePickerEnd.set('minDate', this.startDate);
				this.getReport();
			}
		};
		setTimeout(() => {
			this.datePickerStart = new Flatpickr(
				this.dateInputStart.nativeElement,
				this.locale.current === 'nl'
					? { ...flatPickrConfigStart, locale: Dutch }
					: flatPickrConfigStart
			);
		});

		const flatPickrConfigEnd = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.startDate,
			minDate: this.startDate,
			onChange: (selectedDates, dateStr, instance) => {
				this.endDate = dateStr;
				this.datePickerStart.set('maxDate', this.endDate);
				this.getReport();
			}
		};
		setTimeout(() => {
			this.datePickerEnd = new Flatpickr(
				this.dateInputEnd.nativeElement,
				this.locale.current === 'nl'
					? { ...flatPickrConfigEnd, locale: Dutch }
					: flatPickrConfigEnd
			);
		});
	}

	private getReport(): void {
		this.activity = undefined;
		this.reportsService.getUsageReport(Moment(this.startDate).startOf('day').unix(), Moment(this.endDate).endOf('day').unix(), this.selectedUser, this.selectedCabinet, this.selectedCategory, this.selectedOption, this.selectedStatus).subscribe((data: any) => {
			if (data && data._body) {
				let response: any = JSON.parse(data._body);
				if (response.success) {
					this.activity = response.result || [];
				}
			}
		});
	}

	/**
	 * Emit apply event to the parent component.
	 * @param {object} $event
	 * @return {void}
	 */
	onApplyUserSelect(user: number): void {
		this.selectedUser = user;
		this.getReport();
	}

	onApplyCabinetSelect(cabinet: number): void {
		this.selectedCabinet = cabinet;
		this.getReport();
	}

	onApplyCategorySelect(category: number): void {
		this.selectedCategory = category;
		this.getReport();
	}

	onApplyOptionSelect(option): void {
		this.selectedOption = option;
		this.getReport();
	}

	onApplyStatusSelect(status: number): void {
		this.selectedStatus = status;
		this.getReport();
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);
	}

	public downloadCSV(): void {
		this.reportsService.downloadReport(Moment(this.startDate).startOf('day').unix(), Moment(this.endDate).endOf('day').unix(), this.selectedUser, this.selectedCabinet, this.selectedCategory, this.selectedOption, this.selectedStatus).subscribe((data: any) => {
			let csv = data._body;
			this.createCSVFile(csv);
		});
	}

	private createCSVFile(csv: any): void {
		if (window.navigator.msSaveOrOpenBlob) {
			let blob = new Blob([decodeURIComponent(encodeURI(csv))], {
				type: 'text/csv;charset=utf-8;'
			});
			navigator.msSaveBlob(blob, Date.now() + '.csv');
		} else {
			let a = document.createElement('a');
			a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
			a.target = '_blank';
			a.download = Date.now() + '.csv';
			document.body.appendChild(a);
			a.click();
		}
	}

	public setStateToReturned(item: IUsageReportItem): void {
		item.resetClicked = true;
		this.registrationService.updateRegistration(item.ID, {
			Status: 4
		}).subscribe(() => {
			item.Status = 4;
		}, (error: any) => {
			console.log('error', error);
		});
	}
}

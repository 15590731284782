import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from '../components/app.component';
import { ReservationViewComponent } from '../components/reservation-view/reservation-view.component';
import { LoginViewComponent } from '../components/login-view/login-view.component';
import { ManagementViewComponent } from '../components/management-view/management-view.component';
import { HelpViewComponent } from '../components/help-view/help-view.component';

import { FilterViewComponent } from '../components/reservation-view/filter-view/filter-view.component';
import { OrderViewComponent } from '../components/reservation-view/order-view/order-view.component';
import { ActiveViewComponent } from '../components/reservation-view/active-view/active-view.component';
import { RegistrationViewComponent } from '../components/reservation-view/registration-view/registration-view.component';
import { ConditionsViewComponent } from '../components/reservation-view/conditions-view/conditions-view.component';
import { OnboardingViewComponent } from '../components/onboarding-view/onboarding-view.component';
import { ConfirmationViewComponent } from '../components/reservation-view/confirmation-view/confirmation-view.component';
import { SettingsViewComponent } from '../components/reservation-view/settings-view/settings-view.component';

import { RecipesViewComponent } from '../components/management-view/recipes-view/recipes-view.component';
import { RecipeViewComponent } from '../components/management-view/recipes-view/recipe-view/recipe-view.component';
import { CategoriesViewComponent } from '../components/management-view/categories-view/categories-view.component';
import { CabinetsViewComponent } from '../components/management-view/cabinets-view/cabinets-view.component';
import { CabinetLocationViewComponent } from '../components/management-view/cabinets-view/cabinet-view/location-view/location-view.component';
import { CabinetAdministratorsViewComponent } from '../components/management-view/cabinets-view/cabinet-view/administrators-view/administrators-view.component';
import { CabinetAvailabilityViewComponent } from '../components/management-view/cabinets-view/cabinet-view/availability-view/availability-view.component';
import { CabinetAuditlogViewComponent } from '../components/management-view/cabinets-view/cabinet-view/auditlog-view/auditlog-view.component';
import { CabinetEmailSettingsViewComponent } from '../components/management-view/cabinets-view/cabinet-view/emailsettings-view/emailsettings-view.component';
import { UsersViewComponent } from '../components/management-view/users-view/users-view.component';
import { UserViewComponent } from '../components/management-view/users-view/user-view/user-view.component';
import { ReportsViewComponent } from '../components/management-view/reports-view/reports-view.component';
import { ReportUsageViewComponent } from '../components/management-view/reports-view/report-usage-view/report-usage-view.component';
import { ReportUsageExtendedViewComponent } from '../components/management-view/reports-view/report-usage-extended-view/report-usage-extended-view.component';
import { AdministratorsViewComponent } from '../components/management-view/administrators-view/administrators-view.component';
import { CustomNotificationsViewComponent } from '../components/management-view/customnotifications-view/customnotifications-view.component';
import { CustomNotificationViewComponent } from '../components/management-view/customnotifications-view/customnotification-view/customnotification-view.component';
import { ApplicationSettingsViewComponent } from '../components/management-view/applicationsettings-view/applicationsettings-view.component';
import { ApplicationSettingViewComponent } from '../components/management-view/applicationsettings-view/applicationsetting-view/applicationsetting-view.component';

import { AuthGuard } from './auth-guard';
import { AdHocAuthGuard } from './ad-hoc-auth-guard';
import { ConditionsAuthGuard } from './conditions-auth-guard';
import { CabinetAuthGuard } from './cabinet-auth-guard';

const appRoutes: Routes = [
	{ path: '', children: [

		// Shared login
		{ path: 'login', component: LoginViewComponent },

		// SAML login
		{ path: 'saml', component: LoginViewComponent },

		// SAML validation
		{ path: 'validate', component: LoginViewComponent },

		// Onboarding
		{ path: 'onboarding', component: OnboardingViewComponent },

		// help
		// { path: 'help', component: HelpViewComponent },

		// Reservation
		{ path: 'reservation', component: ReservationViewComponent, children: [
			{ path: 'filter', component: FilterViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'overview', component: FilterViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'active', component: ActiveViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			// { path: 'ict-box', component: OrderViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard, AdHocAuthGuard] }, // defective-item
			// { path: 'drop-box', component: OrderViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard, AdHocAuthGuard] }, // orders/new
			{ path: 'registration/:id', component: RegistrationViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'conditions', component: ConditionsViewComponent, canActivate: [AuthGuard] },
			{ path: 'confirmation', component: ConfirmationViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'settings', component: SettingsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: '', redirectTo: '/reservation/overview', pathMatch: 'full' }
		], canActivate: [AuthGuard]},

		// Management
		{ path: 'management', component: ManagementViewComponent, children: [
			{ path: 'unassigned', component: RecipesViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'recipes', component: RecipesViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'recipe', component: RecipeViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'recipe/:id', component: RecipeViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'categories', component: CategoriesViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'cabinets', component: CabinetsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'cabinet/:id', redirectTo: '/management/cabinet/:id/location', pathMatch: 'full', canActivate: [AuthGuard, ConditionsAuthGuard, CabinetAuthGuard] },
			{ path: 'cabinet/:id/location', component: CabinetLocationViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard, CabinetAuthGuard] },
			{ path: 'cabinet/:id/administrators', component: CabinetAdministratorsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard, CabinetAuthGuard] },
			{ path: 'cabinet/:id/availability', component: CabinetAvailabilityViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard, CabinetAuthGuard] },
			{ path: 'cabinet/:id/auditlog', component: CabinetAuditlogViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard, CabinetAuthGuard] },
			{ path: 'cabinet/:id/emailsettings', component: CabinetEmailSettingsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard, CabinetAuthGuard] },
			{ path: 'users', component: UsersViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'user', component: UserViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'user/:id', component: UserViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'reports', component: ReportsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'report/0', component: ReportUsageViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'report/1', component: ReportUsageExtendedViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'administrators', component: AdministratorsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'customnotifications', component: CustomNotificationsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'customnotification/:id', component: CustomNotificationViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'applicationsettings', component: ApplicationSettingsViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: 'applicationsetting/:id', component: ApplicationSettingViewComponent, canActivate: [AuthGuard, ConditionsAuthGuard] },
			{ path: '', redirectTo: '/management/recipes', pathMatch: 'full', canActivate: [AuthGuard, ConditionsAuthGuard] },
		], canActivate: [AuthGuard, ConditionsAuthGuard, AdHocAuthGuard]},

		{ path: '', redirectTo: '/login', pathMatch: 'full' },

		// Exit
		{ path: 'exit', component: LoginViewComponent },

		{ path: '**', redirectTo: '/login', pathMatch: 'full' }
	]}
];

export const appRoutingProviders: any[] = [];
export const AppRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes);
